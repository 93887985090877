<template>
	<div class="page_container">
		<div class="page_title_bg">
			<div class="page_title_flag" />
			<div class="page_title">产品服务</div>
		</div>
		<div class="page_prod">
			<img src="@/assets/images/12131772.jpg" class="page_prod_img" alt="产品与服务-APPintro" title="产品与服务-APPintro" id="img_smv_con_32_57" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'News',
	data() {
	    return {
			url: '@/assets/images/12131772.jpg'
		};
	},
	mounted() {
	
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.page_container{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 80px auto 0;
	max-width: 1200px;
	.page_title_bg{
		margin-top: 32px;
		display: flex;
		align-items: center;
		.page_title_flag{
			margin-right: 5px;
			width: 22px;
			height: 64px;
			background-color: #409eff;
		}
		.page_title{
			font-size: 42px;
			color:#444444; 
			font-family:Microsoft YaHei;
			font-weight: bold;
		}
	}
	.page_prod{
		margin: 20px 0 30px;
		display: flex;
		justify-content: center;
		.page_prod_img{
			width: 100%;
			height: 100%;
		}
	}
}
@media (max-width:940px) {
	.page_container{
		.page_title_bg{
			.page_title_flag{
				margin-left: 12px;
				width: 14px;
				height: 42px;
			}
			.page_title{
				font-size: 28px;
			}
		}
	}
}
</style>